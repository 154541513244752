import Link from 'next/link'
import {useVisibility} from '@helpers/scrollHelper'
import ImpactCardSmall from '@ui/blocks/ImpactCardSmall/page'
import {useRouter} from 'next/router'
import Image from 'next/image'

const cardItems = [
	{
		slug: 'game-day-impact',
		backgroundColor: '#d33337',
		backgroundImageSrc: '/assets/images/landingpage/collection/cards/collection-demo-1.png',
		owner: {
			name: 'Adidas',
			avatar: {
				default: 'https://picsum.photos/200/200',
				'96x96': 'https://picsum.photos/96/96',
				'160x160': 'https://picsum.photos/160/160',
				'320x320': 'https://picsum.photos/320/320',
			},
		},
		title: 'Game Day Impact',
		rewards: ['ticket', 'discount', 'placeholder'],
	},
	{
		slug: 'zoo-visit-impact',
		backgroundColor: '#71911d',
		backgroundImageSrc: '/assets/images/landingpage/collection/cards/collection-demo-2.png',
		owner: {
			name: 'Adidas',
			avatar: {
				default: 'https://picsum.photos/200/200',
				'96x96': 'https://picsum.photos/96/96',
				'160x160': 'https://picsum.photos/160/160',
				'320x320': 'https://picsum.photos/320/320',
			},
		},
		title: 'Zoo Visit Impact',
		rewards: ['ticket', 'discount', 'placeholder'],
	},
	{
		slug: 'runs-with-impact',
		backgroundColor: '#fb7c27',
		backgroundImageSrc: '/assets/images/landingpage/collection/cards/collection-demo-3.png',
		owner: {
			name: 'Adidas',
			avatar: {
				default: 'https://picsum.photos/200/200',
				'96x96': 'https://picsum.photos/96/96',
				'160x160': 'https://picsum.photos/160/160',
				'320x320': 'https://picsum.photos/320/320',
			},
		},
		title: 'Runs with Impact',
		rewards: ['ticket', 'discount', 'placeholder'],
	},
	{
		slug: 'events-with-impact',
		backgroundColor: '#c73c79',
		backgroundImageSrc: '/assets/images/landingpage/collection/cards/collection-demo-4.png',
		owner: {
			name: 'Adidas',
			avatar: {
				default: 'https://picsum.photos/200/200',
				'96x96': 'https://picsum.photos/96/96',
				'160x160': 'https://picsum.photos/160/160',
				'320x320': 'https://picsum.photos/320/320',
			},
		},
		title: 'Events with Impact',
		rewards: ['ticket', 'discount', 'placeholder'],
	},
	{
		slug: 'impact-on-tour',
		backgroundColor: '#153552',
		backgroundImageSrc: '/assets/images/landingpage/collection/cards/collection-demo-5.png',
		owner: {
			name: 'Adidas',
			avatar: {
				default: 'https://picsum.photos/200/200',
				'96x96': 'https://picsum.photos/96/96',
				'160x160': 'https://picsum.photos/160/160',
				'320x320': 'https://picsum.photos/320/320',
			},
		},
		title: 'Impact on Tour',
		rewards: ['ticket', 'discount', 'placeholder'],
	},
]

export default function Collection() {
	const router = useRouter()
	const [isVisible, scrollElement, scrollPosition] = useVisibility<HTMLDivElement>()

	const calculateTranslate = (start: number, end: number, offset = 250) => {
		const elementEndPosition = scrollElement?.current?.offsetTop - offset

		const factor = 0.005

		if (start < 0) {
			return Math.min(end, start * ((elementEndPosition - scrollPosition) * factor))
		} else {
			return Math.max(end, start * ((elementEndPosition - scrollPosition) * factor))
		}
	}

	const calculateOpacity = (offset = 250) => {
		const elementEndPosition = scrollElement?.current?.offsetTop - offset
		return Math.min(1, scrollPosition / elementEndPosition)
	}

	const openCardViewer = (item) => {
		router.push('/certificates')
		// TODO open the card viewer, once the cards are created, for now just link to the certificates page
		// router.push(router.asPath.split('?')[0] + '?card-viewer-id=' + item.slug, undefined, {
		// 	shallow: true,
		// })
	}

	return (
		<div className="section-container">
			<div className="sub-container tw-relative tw-overflow-hidden tw-rounded-xl tw-bg-gray-800 tw-p-5 lg:tw-p-20" ref={scrollElement}>
				{/* Background Image Pattern */}
				<Image
					src="/assets/images/landingpage/collection/circles-and-squares.svg"
					layout="fill"
					className="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover tw-opacity-40"
					alt="pattern background image"
					unoptimized
				/>
				<div className="tw-flex tw-h-full tw-flex-col">
					{/* Mobile Just One */}
					<div className="tw-flex tw-items-center tw-justify-center tw-gap-4 sm:tw-hidden">
						<ImpactCardSmall {...cardItems[1]} slug={`${cardItems[1].slug}-mobile`} onClick={() => openCardViewer(cardItems[1].slug)} />
					</div>
					{/* Tablet Three Elements */}
					<div className="tw-hidden tw-items-center tw-justify-center tw-gap-4 sm:tw-flex lg:tw-hidden">
						{cardItems.slice(0, 3).map((item, index) => (
							<ImpactCardSmall
								{...item}
								slug={`${item.slug}-tablet`}
								key={item.title}
								style={{
									transform: `translateY(${calculateTranslate(((index + 1) % 2 ? 1 : -1) * ((index + 1) * 0.2) * -200, 0)}px)`,
									opacity: `${calculateOpacity()}`,
									transition: 'transform opacity 0.1s',
								}}
								onClick={() => openCardViewer(item.slug)}
							/>
						))}
					</div>
					{/* Desktop */}
					<div className="tw-hidden tw-items-center tw-justify-center tw-gap-4 lg:tw-flex">
						{cardItems.map((item, index) => (
							<ImpactCardSmall
								{...item}
								key={item.title}
								style={{
									transform: `translateY(${calculateTranslate(((index + 1) % 2 ? 1 : -1) * ((index + 1) * 0.2) * -200, 0)}px)`,
									opacity: `${calculateOpacity()}`,
									transition: 'transform opacity 0.1s',
								}}
								onClick={() => openCardViewer(item.slug)}
							/>
						))}
					</div>

					<div className="tw-mt-10 tw-flex tw-items-center tw-justify-center tw-text-center">
						<div className="tw-relative tw-flex tw-flex-col tw-items-center">
							<h2 className="tw-text-white lg:tw-w-2/3 lg:!tw-leading-[3.5rem]">
								Build your collection, unlock <span className="tw-underline">more rewards</span> and create an{' '}
								<span className="tw-relative tw-ml-3 tw-overflow-visible">
									impact
									<div className="tw-absolute tw-left-[50%] tw-top-[55%] tw-h-[90px] tw-min-w-[180%] tw--translate-x-1/2 tw--translate-y-1/2 -tw-rotate-[2deg] lg:tw-left-[55%] lg:tw-top-[50%] lg:tw-h-[140px]">
										<Image src="/assets/images/landingpage/collection/scribble-square.png" alt="scribble image" layout="fill" sizes="300" />
									</div>
								</span>
							</h2>

							<p className="tw-w-full tw-text-lg tw-text-white lg:tw-w-2/3 lg:tw-text-xl">
								Keep adding to your Impact Card collection and unlock more rewards, exclusive offers, and greater impact with every card. Your journey has just started!
							</p>
							<div className="tw-mt-8 tw-flex tw-flex-col tw-gap-4 lg:tw-flex-row">
								<Link href="/certificates">
									<div className="button-primary !tw-bg-white !tw-text-gray-800">Browse all cards</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
