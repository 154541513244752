import Header from './Header'

interface Props {
	slug: string
	backgroundImageSrc: string
	backgroundColor: string
	owner: {
		name: string
		avatar: any
	}
	title: string
	rewards: string[]
	style?: object
	onClick?: (slug: string) => void
}

export default function ImpactCardSmall(props: Props) {
	return (
		<div style={{...props.style}}>
			<div
				className="tw-group tw-flex tw-aspect-[1.3/2] tw-min-w-[150px] tw-max-w-[150px] tw-cursor-pointer tw-flex-col tw-overflow-hidden tw-rounded-xl tw-transition hover:tw-scale-95"
				onClick={() => props.onClick(props.slug)}
				style={{backgroundColor: props.backgroundColor}}
			>
				<div className="tw-h-[150px] tw-w-full">
					<Header {...props} />
				</div>
				<h4 className="tw-flex-1 tw-p-2 tw-text-lg tw-font-bold tw-text-white">{props.title}</h4>
			</div>
		</div>
	)
}
