import {MutableRefObject, useCallback, useEffect, useRef, useState} from 'react'

/**
 * Check if an element is in viewport
 * @param {number} offset - Number of pixels up to the observable element from the top
 */
export function useVisibility<T>(offset = 0): [boolean, MutableRefObject<T>, number, number] {
	const [scrollPosition, setScrollPosition] = useState(0)
	const [isVisible, setIsVisible] = useState(false)
	const [screenWidth, setScreenWidth] = useState(0)
	const currentElement = useRef(null)

	const onScroll = useCallback(() => {
		if (!currentElement.current) {
			setIsVisible(false)
			return
		}
		setScrollPosition(window.scrollY)
		const top = currentElement.current.getBoundingClientRect().top
		setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight)
	}, [offset])

	useEffect(() => {
		setScreenWidth(window.innerWidth)
		document.addEventListener('scroll', onScroll, true)
		return () => document.removeEventListener('scroll', onScroll, true)
	}, [onScroll])

	return [isVisible, currentElement, scrollPosition, screenWidth]
}

/**
 * Check if an element is in viewport
 * @param {number} offset - Number of pixels up to the observable element from the top
 */
export function useIsElementTopAboveScroll<T>(offset = 0): [boolean, MutableRefObject<T>, number] {
	const [scrollPosition, setScrollPosition] = useState(0)
	const [isTopAboveScroll, setIsTopAboveScroll] = useState(false)
	const currentElement = useRef(null)
	const [initialTop, setInitialTop] = useState(0)

	const onScroll = useCallback(() => {
		if (!currentElement.current) {
			setIsTopAboveScroll(false)
			return
		}
		setScrollPosition(window.scrollY)
		setIsTopAboveScroll(scrollPosition > initialTop + offset)
	}, [scrollPosition, initialTop, offset])

	useEffect(() => {
		document.addEventListener('scroll', onScroll, true)
		return () => document.removeEventListener('scroll', onScroll, true)
	}, [onScroll])

	useEffect(() => {
		if (currentElement.current) {
			setInitialTop(currentElement.current.getBoundingClientRect().top)
		}
	}, [currentElement])

	return [isTopAboveScroll, currentElement, scrollPosition]
}
